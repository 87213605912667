import React from "react";
import { node } from "prop-types";
import Helmet from "react-helmet";
import config from "../../../data/SiteConfig";
import "typeface-roboto";
import "typeface-khand";

const Layout = ({
  children,
  customTitle,
  layoutStyle = {},
  bgColor = "bg-blue-1210",
}) => (
  <div
    style={layoutStyle}
    className={`${bgColor} w-full h-full text-black font-roboto leading-normal
   tracking-normal min-h-screen `}
  >
    <Helmet
      title={(customTitle ? customTitle : "Fincity" + " | ") + config.siteTitle}
    >
      <meta name="description" content={config.siteDescription} />
      <html lang="en" />
      <link rel="icon" href={config.faviconURL} type="image/icon type" />
    </Helmet>
    {children}
  </div>
);
Layout.propTypes = {
  children: node.isRequired,
};

export default Layout;
